import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
        <path
          d="M40,26 A20,20 0 1,1 40,66 A20,20 0 1,1 40,26 M40,36 A10,10 0 1,0 40,56 A10,10 0 1,0 40,36"
          fill="currentColor"></path>
      </g>
    </g>
  </svg>
);

export default IconLogo;
